$primary: #1fc36a;
$primary1: #1fc36a;
$primary2: #216869;
$primary3: #5c4ae4;
$gray1: #444444;
$gray2: #666666;
$gray3: #888888;
$gray4: #cccccc;
$gray5: #eeeeee;
$gray6: #efefef;

$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;


.force-w-screen {
	width: 100vw;
	margin-left: calc(-50vw + 50%);
	margin-right: calc(-50vw + 50%);
}

.autocompleter::-webkit-scrollbar {
	background-color: #fff;
	width: 16px;
	border-radius: 16px;
}

/* background of the scrollbar except button or resizer */
.autocompleter::-webkit-scrollbar-track {
	background-color: #fff;
	border-radius: 16px;
}
.autocompleter::-webkit-scrollbar-track:hover {
	background-color: #f4f4f4
}

/* scrollbar itself */
.autocompleter::-webkit-scrollbar-thumb {
	background-color: #babac0;
	border-radius: 16px;
	border: 5px solid #fff
}
.autocompleter::-webkit-scrollbar-thumb:hover {
	background-color: #a0a0a5;
	border: 4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.autocompleter::-webkit-scrollbar-button { display: none }

