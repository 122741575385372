$primary: #1fc36a;
$primary1: #1fc36a;
$primary2: #216869;
$primary3: #5c4ae4;
$gray1: #444444;
$gray2: #666666;
$gray3: #888888;
$gray4: #cccccc;
$gray5: #eeeeee;
$gray6: #efefef;

$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;


.text-leafwell-green {
	color: #01caaa;
}
.content :deep(h2) {
	@apply font-bold text-2xl py-4;
}
.content :deep(ul) {
	@apply list-disc pl-12;
}
.content :deep(li) {
	@apply leading-relaxed;
}
